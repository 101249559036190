<template>
  <div class="row mb-4">
    <ul class="col-12 commented">
      <li class="comm-inf">
        <div class="col-md-2">
          <img
            v-lazyload
            :data-url="
              user
                ? getProfilePic(user.profilePic)
                : require('../assets/images/default.webp')
            "
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="col-md-10 comments-info text-left">
          <h5 class="mb-1">{{ user ? user.name : "" }}</h5>
          <p class="mb-4">
            {{ comment.createdAtDate | convertDate }}
          </p>
          <p>
            {{ comment.comment }}
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from "moment"
export default {
    data(){
        return{
            user: null
        }
    },
  props: ["comment"],
  filters: {
    convertDate: (value) => {
      let splitDate = value.split("-");
      return moment(`${splitDate[1]}-${splitDate[0]}-${splitDate[2]}`).format(
        "MMM DD, YYYY"
      );
    },
  },
  mounted(){
      this.getUser(this.comment.userId)
  },
  methods: {
    getProfilePic(pic) {
      try {
        let pic = JSON.parse(this.user.profilePic);
        return pic[0];
      } catch (err) {
        return this.user.profilePic;
      }
    },
    getUser(id) {
      this.$store.dispatch("getBuyerSellerForBlog", id).then((res) => {
        if (res.data.success) {
          this.user = res.data.data;
        }
      });
    },
  },
};
</script>

<style scoped src="../assets/css/styles.css"></style>

<style scoped>
</style>